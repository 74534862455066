import './App.css'
import AppRoutes from './routes/AppRoutes'
import NavBar from './components/NavBar/NavBar'
import { useContext } from 'react'
import { AuthContext } from './context/auth.context'
// import NotLoggedNavbar from './components/NotLoggedNavbar/NotLoggedNavbar'
// import Footer from './components/Footer/Footer'

function App() {
  const { isLoggedIn } = useContext(AuthContext)

  return (
    <>
      {isLoggedIn && <NavBar />}
      {/* {!isLoggedIn && <NotLoggedNavbar />} */}
      <AppRoutes />
      {/* <Footer /> */}
    </>
  )
}

export default App
