import { useContext, useState } from 'react'
import authService from '../../services/auth.service'
import { AuthContext } from '../../context/auth.context'
import { MessageContext } from '../../context/userMessage.context'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Btn, PrimaryBtn } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const LoginSubmitBtn = styled.button`
  ${Btn}
  ${PrimaryBtn}
`

const LoginFormComp = styled.form`
  width: 95%;
  margin: 1rem auto;
`

const LoginPasswordGroup = styled.div`
  display: flex;
`

const LoginDivPassword = styled.div`
  width: 100%;
`

const LoginPasswordInput = styled.input`
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const ShowPasswordButton = styled.button`
  border: 1px solid #ced4da;
  border-left: none;
  background: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.374rem;
`
const LoginRememberMe = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
`

const ForgotPassword = styled(Link)`
  color: red !important;
`

const LoginForm = () => {
  const [passwordShown, setPasswordShown] = useState(false)
  const [isPasswordWrong, setIsPasswordWrong] = useState(false)
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  })
  const { storeToken, authenticateUser, setRememberUser } =
    useContext(AuthContext)
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const toggleShowPassword = () => {
    setPasswordShown(!passwordShown)
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setLoginForm({
      ...loginForm,
      [name]: value
    })
  }

  const handleRememberUserChange = e => {
    setRememberUser(e.target.checked)
  }

  const handleSubmit = e => {
    e.preventDefault()

    authService
      .login(loginForm)
      .then(({ data }) => {
        storeToken(data.authToken)
        authenticateUser()
        navigate('/expenses')
      })
      .catch(err => {
        const errorMessage = err.response?.data.message
        if (
          errorMessage === 'Incorrect password' ||
          errorMessage === 'Email and password are needed'
        ) {
          setIsPasswordWrong(true)
        }
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  return (
    <LoginFormComp onSubmit={handleSubmit}>
      <div className='form-floating mb-3'>
        <input
          type='email'
          className='form-control'
          id='loginInputEmail'
          placeholder='name@example.com'
          name='email'
          value={loginForm.email}
          onChange={handleInputChange}
        />
        <label htmlFor='loginInputEmail'>{t('login_form.email')}</label>
      </div>
      <LoginPasswordGroup>
        <LoginDivPassword className='form-floating'>
          <LoginPasswordInput
            type={passwordShown ? 'text' : 'password'}
            className='form-control'
            id='loginInputPassword'
            placeholder='Password'
            name='password'
            value={loginForm.password}
            onChange={handleInputChange}
          />
          <label htmlFor='loginInputPassword'>{t('login_form.password')}</label>
        </LoginDivPassword>
        <ShowPasswordButton
          type='button'
          onClick={toggleShowPassword}
          tabIndex='-1'
        >
          {passwordShown ? (
            <i className='fa-solid fa-eye-slash'></i>
          ) : (
            <i className='fa-solid fa-eye'></i>
          )}
        </ShowPasswordButton>
      </LoginPasswordGroup>
      <LoginRememberMe className='mb-3 form-check'>
        <div>
          <input
            type='checkbox'
            className='form-check-input'
            id='rememberMe'
            onChange={handleRememberUserChange}
          />
          <label className='form-check-label' htmlFor='rememberMe'>
            {t('login_form.remember_me')}
          </label>
        </div>
        {isPasswordWrong && (
          <ForgotPassword to='/forgot_password'>
            {t('login_form.forgot_password')}
          </ForgotPassword>
        )}
      </LoginRememberMe>
      <LoginSubmitBtn>{t('login_form.submit')}</LoginSubmitBtn>
    </LoginFormComp>
  )
}

export default LoginForm
