import { ListGroup } from 'react-bootstrap'
import styled from 'styled-components'

export const SettingsOptions = styled(ListGroup)`
  width: 90%;
  margin: 3rem auto;
`

export const LogOut = styled(ListGroup.Item)`
  background-color: red;
  color: white;
  font-weight: 700;
  cursor: pointer;
`
