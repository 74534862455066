import { useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { MessageContext } from '../../context/userMessage.context'
import authService from '../../services/auth.service'
import { Btn, MobileContainer, PrimaryBtn } from '../../utils/mixins'

const Container = styled.div`
  ${MobileContainer}
  text-align: center;
`
const SubmitBtn = styled.button`
  ${Btn}
  ${PrimaryBtn}
`

const ChangePasswordGroup = styled.div`
    display: flex;
`

const ChangePasswordDiv = styled.div`
    width: 100%;
`

const ChangePasswordInput = styled.input`
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`

const ShowPasswordButton = styled.button`
    border: 1px solid #ced4da;
    border-left: none;
    background: none;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.374rem;
`

const NotMatchPass = styled.p`
    color: red;
    text-align: left;
    margin-bottom: 1rem;
`

const ChangePasswordPage = () => {

  const [changePasswordForm, setChangePasswordForm] = useState({ new_password: '' })
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const [disabled, setDisabled] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  // eslint-disable-next-line
  const [idParams, setIdParams] = useSearchParams({ id: '' })

  const navigate = useNavigate()
  const togglePassword = () => setPasswordShown(!passwordShown)
  const toggleConfirmPassword = () => setConfirmPasswordShown(!confirmPasswordShown)
  const hashId = idParams.get('id')

  const handleInputChange = e => {
    const { name, value } = e.target

    setChangePasswordForm({
      ...changePasswordForm,
      [name]: value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    authService
      .changePassword(hashId, changePasswordForm)
      .then(() => {
        navigate('/')
        setShowMessage(true)
        setMessageInfo({
          title: 'Success',
          desc: 'Your password has been change'
        })
      })
      .catch((err) => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  const passwordMatch = e => {
    if (changePasswordForm.new_password === '') {
      setDisabled(false)
    } else if (e.target.value !== changePasswordForm.new_password) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <Container>
      <h1>Change your password</h1>
      <p>Enter your new password.</p>

      <form onSubmit={handleSubmit}>
        <ChangePasswordGroup className='mb-3'>
          <ChangePasswordDiv className="form-floating">
            <ChangePasswordInput
              type={passwordShown ? "text" : "password"}
              className="form-control"
              id="changePasswordInput"
              placeholder="Password"
              name='new_password'
              value={changePasswordForm.new_password}
              onChange={handleInputChange}
              autoComplete="new-password"
            />
            <label htmlFor="changePasswordInput">New password</label>
          </ChangePasswordDiv>
          <ShowPasswordButton type="button" onClick={togglePassword}>
            {
              passwordShown
                ?
                <i className="fa-solid fa-eye-slash"></i>
                :
                <i className="fa-solid fa-eye"></i>
            }
          </ShowPasswordButton>
        </ChangePasswordGroup>

        <ChangePasswordGroup className='mb-3'>
          <ChangePasswordDiv className="form-floating">
            <ChangePasswordInput
              type={confirmPasswordShown ? "text" : "password"}
              className="form-control"
              id="confirmPasswordInput"
              placeholder="Confirm password"
              autoComplete="new-password"
              onChange={passwordMatch}
              required
            />
            <label htmlFor="confirmPasswordInput">Confirm new password</label>
          </ChangePasswordDiv>
          <ShowPasswordButton type="button" onClick={toggleConfirmPassword}>
            {
              confirmPasswordShown
                ?
                <i className="fa-solid fa-eye-slash"></i>
                :
                <i className="fa-solid fa-eye"></i>
            }
          </ShowPasswordButton>
        </ChangePasswordGroup>
        <NotMatchPass>{disabled ? "Passwords does not match" : ""}</NotMatchPass>

        <SubmitBtn type='submit'>Submit</SubmitBtn>
      </form>
    </Container>
  )
}

export default ChangePasswordPage
