import { createContext, useState, useEffect } from 'react'
import authService from '../services/auth.service'

const AuthContext = createContext()

function AuthProviderWrapper(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [rememberUser, setRememberUser] = useState(false)
  const [user, setUser] = useState(null)

  const storeToken = token => {
    rememberUser ? localStorage.setItem('authToken', token) : sessionStorage.setItem('authToken', token)
  }

  const removeToken = () => {
    rememberUser ? localStorage.removeItem('authToken') : sessionStorage.removeItem('authToken')
  }

  const getToken = () => {
    return rememberUser ? localStorage.getItem('authToken') : sessionStorage.getItem('authToken')
  }

  const authenticateUser = () => {
    const storedToken = getToken()

    if (!storedToken) {
      logOutUser()
    } else {
      authService
        .verify(storedToken)
        .then(({ data }) => {
          const user = data
          setIsLoggedIn(true)
          setIsLoading(false)
          setUser(user)
        })
        .catch(() => logOutUser())
    }
  }

  const logOutUser = () => {
    removeToken()
    setIsLoggedIn(false)
    setIsLoading(false)
    setUser(null)
  }

  useEffect(() => {
    authenticateUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, isLoading, user, rememberUser, storeToken, authenticateUser, logOutUser, setRememberUser }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProviderWrapper }
