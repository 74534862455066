import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const PageNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & h3 {
    text-align: center;
    font-weight: 700;
  }
`

const PageNotFound = () => {
  const { t } = useTranslation()

  return (
    <PageNotFoundContainer>
      <h3>{t('page_not_found')} :(</h3>
    </PageNotFoundContainer>
  )
}

export default PageNotFound
