import { css } from 'styled-components'

export const Btn = css`
  display: block;
  width: 95%;
  padding: 1rem 0;
  margin: 0 auto;
  background: none;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid black;
`

export const PrimaryBtn = css`
  border: 3px solid #86bbd8;
  color: white;
  border-radius: 5px;
  background-color: #86bbd8;
  transition: 1s ease-in-out;

  &:hover {
    background-color: white;
    color: #86bbd8;
  }
`

export const MobileContainer = css`
  width: 90%;
  margin: 0 auto;
`

export const HR = css`
  width: 60%;
  margin: 1rem auto;
  border: none;
  height: 6px;
  background-color: #08d371;
`

export const MobileHR = css`
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0.8rem auto;
    width: 50%;
    height: 6px;
    border: none;
    background-color: #08d371;
  }
`
