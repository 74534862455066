import { nextMonthIfPastDay } from '../../utils/tools'
import Expense from '../Expense/Expense'

const IncomingExpensesList = ({ expenses }) => {
  return expenses.map((expense, idx) => {
    const nextMonth = nextMonthIfPastDay(expense.date)

    return <Expense expense={expense} month={nextMonth} key={idx} />
  })
}

export default IncomingExpensesList
