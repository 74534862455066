import styled from 'styled-components'

export const ExpenseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border: 2px solid #86bbd8;
  margin: 1rem 0;
  padding: 5px 10px;
  cursor: pointer;

  @media screen and (min-width: 769px) {
    width: 95%;
    height: 6rem;
    margin: 0.8rem auto;
  }
`

export const ExpenseLeft = styled.div`
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 0;
`

export const ExpenseRight = styled.div`
  text-align: right;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6px 0;
`
