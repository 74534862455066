import { currentEvent, getCurrentMonthNumber } from '../../utils/tools'
import Expense from '../Expense/Expense'
import IncomingExpensesList from '../IncomingExpensesList/IncomingExpensesList'
import { useTranslation } from 'react-i18next'
import * as S from './ExpensesList.styled'

const ExpensesList = ({ expenses }) => {
  const currentMonth = getCurrentMonthNumber()
  const { t } = useTranslation()
  const incomingExpenses = expenses.filter(
    exp => currentEvent(exp.date) === 'past'
  )

  return !expenses.length ? (
    <p>{t('expenses_list.no_expenses')}</p>
  ) : (
    <>
      <S.ExpensesContainer>
        {expenses
          .filter(exp => currentEvent(exp.date) === 'future')
          .map((expense, idx) => {
            return <Expense expense={expense} month={currentMonth} key={idx} />
          })}
      </S.ExpensesContainer>
      {incomingExpenses.length !== 0 && (
        <>
          <S.IncomingExpenses>
            <h4>{t('incoming_expense_list.incoming_expenses')}</h4>
            <S.HrBreak />
          </S.IncomingExpenses>
          <S.ExpensesContainer>
            <IncomingExpensesList expenses={incomingExpenses} />
          </S.ExpensesContainer>
        </>
      )}
    </>
  )
}

export default ExpensesList
