import styled from 'styled-components'
import { HR, MobileContainer } from '../../utils/mixins'

export const Container = styled.div`
  ${MobileContainer}/* padding-bottom: 4rem; */
`
export const HrBreak = styled.hr`
  ${HR}
`
export const TitleHeader = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: -5px;

  & h1 {
    font-size: 3rem;
    font-weight: 600;
  }
`

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (min-width: 769px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
  }
`

export const CategoryContainer = styled.div`
  width: 10rem;
  height: 10rem;
  margin: 5px 5px;
  vertical-align: middle;
  border-radius: 10px;
  font-weight: 700;
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    font-size: 1.2rem;
    color: white;
  }

  @media (max-width: 377px) {
    width: 100%;
  }

  @media screen and (min-width: 769px) {
    width: 95%;
    margin: 1rem auto;
    cursor: pointer;
  }
`
