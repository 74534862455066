import { useEffect } from 'react'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MessageContext } from '../../context/userMessage.context'
import expensesService from '../../services/expenses.service'
import { expenseCategories } from '../../utils/library'
import styled from 'styled-components'
import { Btn } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const EditExpenseButtons = styled.div`
  text-align: center;
  display: flex;
  width: 80%;
  margin: 0 auto;
`
const CancelBtn = styled.button`
  ${Btn}
  line-height: 3rem;
  border: 2px solid #86bbd8;
  border-radius: 5px;
  color: #86bbd8;
  transition: 0.3s ease-in;

  &:hover {
    color: white;
    background-color: #86bbd8;
  }
`

const SaveBtn = styled.button`
  ${Btn}
  border: 2px solid #86BBD8;
  border-radius: 5px;
  margin-right: 8px;
  color: white;
  background-color: #86bbd8;
  transition: 0.3s ease-in;

  &:hover {
    color: #86bbd8;
    background: none;
  }
`

const EditExpenseForm = () => {
  const [editExpenseForm, setEditExpenseForm] = useState({
    name: '',
    amount: 0,
    date: 1,
    category: ''
  })
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const navigate = useNavigate()
  const { expense_id } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    expensesService
      .getOneExpense(expense_id)
      .then(({ data }) => setEditExpenseForm(data))
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = e => {
    const { name, value } = e.target

    setEditExpenseForm({
      ...editExpenseForm,
      [name]: value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    expensesService
      .updateExpenses(expense_id, editExpenseForm)
      .then(() => navigate('/expenses'))
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='form-floating mb-3'>
        <input
          type='text'
          className='form-control'
          id='newExpenseNameInput'
          placeholder='Name'
          name='name'
          value={editExpenseForm.name}
          onChange={handleInputChange}
        />
        <label htmlFor='newExpenseNameInput'>{t('expense_form.name')}</label>
      </div>

      <div className='form-floating mb-3'>
        <input
          type='number'
          min='0'
          step='any'
          className='form-control'
          id='newExpenseAmountInput'
          placeholder='Amount'
          name='amount'
          value={editExpenseForm.amount}
          onChange={handleInputChange}
        />
        <label htmlFor='newExpenseAmountInput'>{t('expense_form.amount')}</label>
      </div>

      <div className='form-floating mb-3'>
        <select
          className='form-select'
          id='newExpenseDaySelect'
          aria-label='Floating label select example'
          name='date'
          value={editExpenseForm.date}
          onChange={handleInputChange}
        >
          {[...Array(31).keys()].map(day => {
            return (
              <option key={day} value={day + 1}>
                {day + 1}
              </option>
            )
          })}
        </select>
        <label htmlFor='newExpenseDaySelect'>{t('expense_form.month_day')}</label>
      </div>

      <div className='form-floating mb-3'>
        <select
          className='form-select'
          id='newExpenseCategoryInput'
          aria-label='Floating label select example'
          name='category'
          value={editExpenseForm.category}
          onChange={handleInputChange}
        >
          {expenseCategories.map((category, idx) => {
            return (
              <option key={idx} value={category}>
                {t(`categories.${category}`)}
              </option>
            )
          })}
        </select>
        <label htmlFor='newExpenseCategoryInput'>{t('expense_form.category')}</label>
      </div>

      <EditExpenseButtons>
        <SaveBtn type='submit'>{t('expense_form.save')}</SaveBtn>
        <CancelBtn
          type='button'
          onClick={() => {
            navigate(-1)
          }}
        >
          {t('expense_form.cancel')}
        </CancelBtn>
      </EditExpenseButtons>
    </form>
  )
}

export default EditExpenseForm
