import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HR, MobileContainer } from '../../utils/mixins'

export const Container = styled.div`
  ${MobileContainer}
`
export const HeadTitle = styled.h3`
  font-size: 2rem;
  margin: 0.8rem 0;
  font-weight: 400;

  & span {
    text-transform: capitalize;
  }
`

export const ExpensesMonth = styled.p`
  font-size: 1.2rem;

  & span {
    color: #86bbd8;
    font-weight: 600;
  }
`

export const NewExpenseLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 0.6rem 0;
  text-align: center;
  border: 3px solid #86bbd8;
  color: #86bbd8;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 1rem;
  transition: 1s ease-in-out;

  &:hover {
    background-color: #86bbd8;
    color: white;
    border-color: white;
  }

  &:visited {
    color: #86bbd8;
  }

  @media screen and (min-width: 769px) {
    display: inline-block;
    width: 40%;
  }
`

export const TotalExpenses = styled.p`
  font-weight: 600;

  & span {
    font-weight: 300;
  }
`

export const HrBreak = styled.hr`
  ${HR}
`

// Desktop

export const Header = styled.div`
  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
