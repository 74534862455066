import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'
import * as S from './NavBar.styled'

const NavBarComp = () => {
  const { logOutUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <S.NavBar>
        <S.CustomContainer>
          <S.Logo
            onClick={() => navigate('/expenses')}
            className='logo'
            src='https://res.cloudinary.com/andresgarcia/image/upload/v1668545423/reminte/reminte-logo-transparent_vu29zw.png'
            alt='logo'
          />
          <S.Nav>
            <S.CustomNavLink to='/expenses'>{t('navbar.expenses')}</S.CustomNavLink>
            <S.CustomNavLink to='/categories'>{t('navbar.categories')}</S.CustomNavLink>
            <S.CustomNavLink to='/week'>{t('navbar.week')}</S.CustomNavLink>
            <S.CustomNavLink to='/settings'>{t('navbar.settings')}</S.CustomNavLink>
            <S.LogOutBtn
              onClick={() => {
                logOutUser()
                navigate('/')
              }}
            >
              {t('navbar.logout')}
            </S.LogOutBtn>
          </S.Nav>
        </S.CustomContainer>
      </S.NavBar>

      <S.NavbarMobile>
        <S.ImageNavbar
          src='https://res.cloudinary.com/andresgarcia/image/upload/v1680210315/reminte/home-page_gbkcgh.svg'
          alt='home logo'
          onClick={() => navigate('/expenses')}
        />
        <S.ImageNavbar
          src='https://res.cloudinary.com/andresgarcia/image/upload/v1680211076/reminte/categories.svg'
          alt='categories logo'
          onClick={() => navigate('/categories')}
        />
        <S.ImageNavbar
          src='https://res.cloudinary.com/andresgarcia/image/upload/v1680211458/reminte/week_dystcy.svg'
          alt='week logo'
          onClick={() => navigate('/week')}
        />
        <S.ImageNavbar
          src='https://res.cloudinary.com/andresgarcia/image/upload/v1680211698/reminte/settings_c3y6cu.svg'
          alt='settings logo'
          onClick={() => navigate('/settings')}
        />
      </S.NavbarMobile>
    </>
  )
}

export default NavBarComp
