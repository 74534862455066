import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/auth.context'
import ExpensesList from '../../components/ExpensesList/ExpensesList'
import { getCurrentMonthName } from '../../utils/tools'
import expensesService from '../../services/expenses.service'
import { MessageContext } from '../../context/userMessage.context'
import { useTranslation } from 'react-i18next'
import * as S from './ExpensesPage.styled'

const ExpensesPage = () => {
  const { user } = useContext(AuthContext)
  const [expensesList, setExpensesList] = useState([])
  const [expensesTotal, setExpensesTotal] = useState(0)
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const currentMonth = getCurrentMonthName()
  const { t } = useTranslation()
  const translatedMonth = t(`months.${currentMonth}`)

  const fetchExpenses = () => {
    expensesService
      .getExpenses()
      .then(({ data }) => {
        const sortedExpenses = data.expenses.sort((a, b) => a.date - b.date)
        setExpensesTotal(data.total)
        setExpensesList(sortedExpenses)
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  useEffect(() => {
    fetchExpenses() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Container>
      <S.Header>
        <div>
          <S.HeadTitle>
            <p>
              {t('expenses_page.welcome')}, <span>{user.username}</span>!
            </p>
          </S.HeadTitle>
          <S.ExpensesMonth>
            {t('expenses_page.expenses_resume')} <span>{translatedMonth}</span>
          </S.ExpensesMonth>
          <S.TotalExpenses>
            Total: <span>{expensesTotal}</span>
            {t('currency')}
          </S.TotalExpenses>
        </div>
        <S.NewExpenseLink to='/expenses/new'>
          {t('expenses_page.new_expense')}
        </S.NewExpenseLink>
      </S.Header>
      <S.HrBreak />
      <ExpensesList expenses={expensesList} />
    </S.Container>
  )
}

export default ExpensesPage
