import { useContext, useState } from 'react'
import styled from 'styled-components'
import { MessageContext } from '../../context/userMessage.context'
import authService from '../../services/auth.service'
import { Btn, MobileContainer, PrimaryBtn } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'
import UserMessage from '../../components/UserMessage/UserMessage'

const Container = styled.div`
  ${MobileContainer}
  text-align: center;
  box-shadow: 0px 9px 8px 0px #86bbd8;
  border-radius: 5px;
  padding: 10px;

  & p {
    margin: 10px 0 25px 0;
  }
`

const SubmitBtn = styled.button`
  ${Btn}
  ${PrimaryBtn}
`

const SuccessEmail = styled.div`
  border: none;
  background-color: #b4ffb4;
  padding: 10px 8px;

  & p {
    margin: 0;
  }
`

const ForgotPasswordPage = () => {
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const [changePasswordForm, setChangePasswordForm] = useState({ email: '' })
  const [emailSent, setEmailSent] = useState(false)
  const { t } = useTranslation()

  const handleInputChange = e => {
    const { name, value } = e.target

    setChangePasswordForm({
      ...changePasswordForm,
      [name]: value
    })
  }

  const handleFormSubmit = e => {
    e.preventDefault()

    if (changePasswordForm.email !== '') {
      authService
        .requestChangePassword(changePasswordForm.email)
        .then(() => setEmailSent(true))
        .catch(err => {
          setShowMessage(true)
          setMessageInfo({
            title: 'Error',
            desc: err.response.data.message
          })
        })
    } else {
      setShowMessage(true)
      setMessageInfo({
        title: 'Error',
        desc: 'Enter a valid email.'
      })
    }
  }

  return (
    <Container>
      <h1>{t('forgot_password_page.forgot_password')}</h1>
      <p>{t('forgot_password_page.description')}</p>

      {!emailSent ? (
        <form onSubmit={handleFormSubmit}>
          <div className='form-floating mb-3'>
            <input
              type='email'
              className='form-control'
              id='floatingInput'
              placeholder='name@example.com'
              name='email'
              value={changePasswordForm.email}
              onChange={handleInputChange}
            />
            <label htmlFor='floatingInput'>{t('forgot_password_page.email')}</label>
          </div>
          <SubmitBtn type='submit'>{t('forgot_password_page.submit')}</SubmitBtn>
        </form>
      ) : (
        <>
          <SuccessEmail>
            <p>{t('forgot_password_page.email_sent')}</p>
          </SuccessEmail>
          <UserMessage />
        </>
      )}
    </Container>
  )
}

export default ForgotPasswordPage
