import styled from 'styled-components'
import { HR } from '../../utils/mixins'

export const ExpensesContainer = styled.div`
  @media screen and (min-width: 769px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6px;
  }
`

export const HrBreak = styled.hr`
  ${HR}
`
export const IncomingExpenses = styled.div`
  text-align: center;
  margin: 1rem 0 1.8rem;

  & h4 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: -10px;
  }
`
