import { useContext } from 'react'
import { AuthContext } from '../../context/auth.context'
import LoginPage from '../../pages/LoginPage/LoginPage'

const RequireAuth = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext)

  if (!isLoggedIn) {
    return <LoginPage />
  }

  return children
}

export default RequireAuth
