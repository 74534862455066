import NewExpenseForm from '../../components/NewExpenseForm/NewExpenseForm'
import styled from 'styled-components'
import { MobileContainer } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  ${MobileContainer}

  & h3 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }
`

const NewExpensePage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <h3>{t('new_expense_page')}</h3>
      <NewExpenseForm />
    </Container>
  )
}

export default NewExpensePage
