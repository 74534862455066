import styled from 'styled-components'
import WeekExpensesList from '../../components/WeekExpensesList/WeekExpensesList'
import { HR, MobileContainer } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const HrBreak = styled.hr`
  ${HR}
`
const WeekTitle = styled.div`
  text-align: center;
  margin-top: 1rem;
`

const Container = styled.div`
  ${MobileContainer}
`

const WeekPage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <WeekTitle>
        <h1>{t('week_page')}</h1>
        <HrBreak />
      </WeekTitle>
      <WeekExpensesList />
    </Container>
  )
}

export default WeekPage
