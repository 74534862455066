import styled from 'styled-components'
import { Btn } from '../../utils/mixins'
import { NavLink } from 'react-router-dom'

// Mobile

export const NavbarMobile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    background-color: #86bbd8;
    width: 100%;
    height: 4rem;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`

export const ImageNavbar = styled.img`
  width: 70%;
  height: 70%;
`

// Desktop

export const Logo = styled.img`
  width: 10rem;
  cursor: pointer;
`

export const CustomNavLink = styled(NavLink)`
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0 10px;

  &.active {
    color: #86bbd8;
    font-weight: 700;
  }
`

export const LogOutBtn = styled.button`
  ${Btn}
  display: inline-block;
  background-color: transparent;
  color: red;
  font-weight: 700;
  padding: 5px 15px;
  width: auto;
  border-radius: 5px;
  border-color: red;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;

  &:hover {
    color: white;
    border-color: white;
    background-color: red;
  }
`

export const NavBar = styled.div`
  display: none;
  margin-bottom: 1rem;
  padding-top: 1rem;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

export const CustomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
