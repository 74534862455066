import { useContext } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { MessageContext } from '../../context/userMessage.context'
import authService from '../../services/auth.service'
import { Btn, MobileContainer } from '../../utils/mixins'

const Container = styled.div`
  ${MobileContainer}
  max-width: 40rem;
  margin: 0 auto;
`
const MainDiv = styled.div`
  text-align: center;
  box-shadow: 0px 0px 10px 0px #86BBD8;
  border-radius: 5px;
  padding: 10px;

  & h3 {
    margin-bottom: 2rem;
  }

  & button {
    ${Btn}
    color: white;
    background-color: #86BBD8;
    border: none;
    border-radius: 3px;
    transition: .3s ease-in;
  }

  & button:hover {
    color: #86BBD8;
    background: none;
    border: 3px solid #86BBD8;
  }
`

const ConfirmEmailPage = () => {

  // eslint-disable-next-line
  const [idParams, setIdParams] = useSearchParams({ id: '' })
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const navigate = useNavigate()

  const hashId = idParams.get('id')

  const confirmEmail = () => {
    authService
      .verify_email(hashId)
      .then(() => {
        navigate('/')
        setShowMessage(true)
        setMessageInfo({
          title: 'Success',
          desc: 'You email has been confirmed!'
        })
      })
      .catch((err) => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  return (
    <Container>
      <MainDiv>
        <h3>Thanks for registering in Reminte!</h3>
        <button onClick={confirmEmail}>Confirm email</button>
      </MainDiv>
    </Container>
  )
}

export default ConfirmEmailPage
