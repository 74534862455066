import axios from 'axios'

class ExpensesService {
  constructor() {
    this.axios = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` })

    this.axios.interceptors.request.use(config => {
      const storedToken = localStorage.getItem('authToken') || sessionStorage.getItem('authToken')

      if (storedToken) {
        config.headers = { Authorization: `Bearer ${storedToken}` }
      }

      return config
    })
  }

  createExpense(body) {
    return this.axios.post('/new_expense', body)
  }

  getExpenses() {
    return this.axios.get('/expenses')
  }

  getOneExpense(expense_id) {
    return this.axios.get(`/expenses/${expense_id}`)
  }

  getExpensesByCategory(filter) {
    return this.axios.get(`/expenses/category/${filter}`)
  }

  updateExpenses(expense_id, body) {
    return this.axios.put(`/expense/${expense_id}/update`, body)
  }

  deleteExpenses(expense_id) {
    return this.axios.delete(`/expense/${expense_id}/delete`)
  }
}

const expensesService = new ExpensesService()

export default expensesService
