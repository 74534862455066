import { ListGroup } from 'react-bootstrap'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/auth.context'
import { useTranslation } from 'react-i18next'
import * as S from './SettingsPage.styled'

const SettingsPage = () => {
  const { logOutUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <S.SettingsOptions>
      <ListGroup.Item>
        <Link to='/change_password'>{t('settings_page.change_password')}</Link>
      </ListGroup.Item>
      <S.LogOut
        onClick={() => {
          logOutUser()
          navigate('/')
        }}
      >
        {t('settings_page.log_out')}
      </S.LogOut>
    </S.SettingsOptions>
  )
}

export default SettingsPage
