import { expenseCategories } from '../../utils/library'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as S from './CategoriesPage.styled'

const CategoriesPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <S.Container>
      <S.TitleHeader>
        <h1>{t('categories_page.categories')}</h1>
        <p>{t('categories_page.categories_description')}</p>
      </S.TitleHeader>
      <S.HrBreak />
      {
        <S.CategoriesContainer>
          {expenseCategories.map((category, idx) => {
            return (
              <S.CategoryContainer key={idx} onClick={() => navigate(`/categories/${category}`)}>
                <p>{t(`categories.${category}`).formatStr()}</p>
              </S.CategoryContainer>
            )
          })}
        </S.CategoriesContainer>
      }
    </S.Container>
  )
}

export default CategoriesPage
