import RegisterForm from '../../components/RegisterForm/RegisterForm'
import UserMessage from '../../components/UserMessage/UserMessage'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const NotRegistered = styled.div`
  margin-left: 10px;

  & a {
    color: #86bbd8;
  }
`

const Container = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`

const RegisterHeaderText = styled.h3`
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
`

const RegisterPage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <RegisterHeaderText>{t('register_page.register_now')}</RegisterHeaderText>
      <RegisterForm />
      <NotRegistered>
        <p>
          {t('register_page.no_account')} <a href='/'>{t('register_page.login_now')}</a>
        </p>
      </NotRegistered>
      <UserMessage />
    </Container>
  )
}

export default RegisterPage
