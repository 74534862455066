import LoginForm from '../../components/LoginForm/LoginForm'
import UserMessage from '../../components/UserMessage/UserMessage'
import { useTranslation } from 'react-i18next'
import * as S from './LoginPage.styled'
import { useNavigate } from 'react-router-dom'

const LoginPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <S.Container>
      <S.DescriptionContainer>
        <div onClick={() => navigate('/')}>
          <S.LogoReminte
            src='https://res.cloudinary.com/andresgarcia/image/upload/v1668545423/reminte/reminte-logo-transparent_vu29zw.png'
            alt='logo'
          />
        </div>
        <p>{t('login_page.description')}</p>
      </S.DescriptionContainer>
      <S.FormContainer>
        <S.LogIn>Log in</S.LogIn>
        <LoginForm />
        <S.NotRegistered>
          <p>
            {t('login_page.no_account')}{' '}
            <a href='/sign_up'>{t('login_page.register_now')}</a>
          </p>
        </S.NotRegistered>
        <UserMessage />
      </S.FormContainer>
    </S.Container>
  )
}

export default LoginPage
