import { useContext, useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MessageContext } from '../../context/userMessage.context'
import expensesService from '../../services/expenses.service'
import { parseToMonthName } from '../../utils/tools'
import { useTranslation } from 'react-i18next'
import * as S from './ExpenseInfoPage.styled'

const ExpenseInfoPage = () => {
  const { expense_id } = useParams()
  const [expense, setExpense] = useState({
    name: '',
    amount: 0,
    date: 1,
    category: ''
  })
  const [expenseDate, setExpenseDate] = useState({ day: '', month: '' })
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const fetchExpense = () => {
    expensesService
      .getOneExpense(expense_id)
      .then(({ data }) => {
        setExpenseDate({
          day: data.date,
          month: parseToMonthName(data.date)
        })
        setExpense(data)
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  const deleteExpense = () => {
    expensesService
      .deleteExpenses(expense_id)
      .then(() => {
        navigate('/expenses')
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  useEffect(() => {
    fetchExpense() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expense_id])

  return (
    <S.Container>
      <S.GoBackBtn
        type='button'
        onClick={() => {
          navigate(-1)
        }}
      >
        {t('expense_info_page.go_back')}
      </S.GoBackBtn>
      <S.ExpenseInfo>
        <S.ExpenseMainInfo>
          <h1>{expense.name.titleize()}</h1>
          <p>
            {expense.amount} {t('currency')}
          </p>
        </S.ExpenseMainInfo>
        <S.ExpenseSecondInfo>
          <p>
            {expenseDate.month} {expenseDate.day}
          </p>
          <p>{t(`categories.${expense.category}`).formatStr()}</p>
        </S.ExpenseSecondInfo>
      </S.ExpenseInfo>
      <S.ExpenseInfoButtons>
        <Link to={`/expenses/${expense_id}/edit`}>
          {t('expense_info_page.edit')}
        </Link>
        <button type='button' onClick={deleteExpense}>
          {t('expense_info_page.delete')}
        </button>
      </S.ExpenseInfoButtons>
    </S.Container>
  )
}

export default ExpenseInfoPage
