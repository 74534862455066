import styled from 'styled-components'
import { Btn, MobileContainer } from '../../utils/mixins'

export const Container = styled.div`
  ${MobileContainer}
`
export const GoBackBtn = styled.button`
  background: none;
  border: 2px solid #86bbd8;
  border-radius: 5px;
  color: #86bbd8;
  font-size: 1.1rem;
  margin-top: 1rem;
  transition: 0.3s ease-in;

  &:hover {
    color: white;
    background-color: #86bbd8;
  }
`

export const ExpenseInfo = styled.div`
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #86bbd8;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 5px;
`

export const ExpenseMainInfo = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  & h1 {
    font-weight: 700;
    margin-bottom: 10px;
  }

  & i {
    color: #08d371;
  }
`

export const ExpenseInfoButtons = styled.div`
  text-align: center;
  display: flex;
  width: 80%;
  margin: 0 auto;

  & a {
    ${Btn}
    line-height: 3rem;
    margin-right: 8px;
    border: 2px solid #86bbd8;
    border-radius: 5px;
    color: #86bbd8;
    transition: 0.3s ease-in;
  }

  & a:hover {
    color: white;
    background-color: #86bbd8;
  }

  & button {
    ${Btn}
    border: 2px solid #86BBD8;
    border-radius: 5px;
    color: white;
    background-color: #86bbd8;
    transition: 0.3s ease-in;
  }

  & button:hover {
    color: #86bbd8;
    background: none;
  }
`

export const ExpenseSecondInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
`
