import { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import LoginPage from '../pages/LoginPage/LoginPage'
import RegisterPage from '../pages/RegisterPage/RegisterPage'
import PageNotFound from '../pages/PageNotFound/PageNotFound'
import ExpensesPage from '../pages/ExpensesPage/ExpensesPage'
import { AuthContext } from '../context/auth.context'
import ExpenseInfoPage from '../pages/ExpenseInfoPage/ExpenseInfoPage'
import NewExpensePage from '../pages/NewExpensePage/NewExpensePage'
import EditExpensePage from '../pages/EditExpensePage/EditExpensePage'
import CategoriesPage from '../pages/CategoriesPage/CategoriesPage'
import CategoryInfoPage from '../pages/CategoryInfoPage/CategoryInfoPage'
import WeekPage from '../pages/WeekPage/WeekPage'
import ConfirmEmailPage from '../pages/ConfirmEmailPage/ConfirmEmailPage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage/ForgotPasswordPage'
import ChangePasswordPage from '../pages/ChangePasswordPage/ChangePasswordPage'
import RequireAuth from '../components/RequireAuth/RequireAuth'
import SettingsPage from '../pages/SettingsPage/SettingsPage'

const AppRoutes = () => {
  const { isLoggedIn } = useContext(AuthContext)

  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/sign_up' element={<RegisterPage />} />
      <Route path='/verify_email' element={<ConfirmEmailPage />} />
      <Route path='/forgot_password' element={<ForgotPasswordPage />} />
      <Route path='/change_password' element={<ChangePasswordPage />} />
      {isLoggedIn && (
        <>
          <Route path='/expenses' element={<ExpensesPage />} />
          <Route path='/expenses/new' element={<NewExpensePage />} />
          <Route path='/expenses/:expense_id' element={<ExpenseInfoPage />} />
          <Route
            path='/expenses/:expense_id/edit'
            element={<EditExpensePage />}
          />
          <Route path='/categories' element={<CategoriesPage />} />
          <Route
            path='/categories/:category_name'
            element={<CategoryInfoPage />}
          />
          <Route path='/week' element={<WeekPage />} />
          <Route path='/settings' element={<SettingsPage />} />
        </>
      )}
      <Route
        path='*'
        element={
          <RequireAuth>
            <PageNotFound />
          </RequireAuth>
        }
      />
    </Routes>
  )
}

export default AppRoutes
