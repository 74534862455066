import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MessageContext } from '../../context/userMessage.context'
import authService from '../../services/auth.service'
import styled from 'styled-components'
import { Btn, PrimaryBtn } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const RegisterButton = styled.button`
  ${Btn}
  ${PrimaryBtn}
`

const LoginFormComp = styled.form`
  width: 95%;
  margin: 1rem auto;
`

const LoginPasswordGroup = styled.div`
  display: flex;
`

const LoginDivPassword = styled.div`
  width: 100%;
`

const LoginPasswordInput = styled.input`
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const ShowPasswordButton = styled.button`
  border: 1px solid #ced4da;
  border-left: none;
  background: none;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.374rem;
`

const NotMatchPass = styled.p`
  color: red;
`

const RegisterForm = () => {
  const [registerForm, setRegisterForm] = useState({
    email: '',
    username: '',
    password: ''
  })
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const [disabled, setDisabled] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toggleShowPassword = () => setPasswordShown(!passwordShown)
  const toggleShowConfirmPassword = () => setConfirmPasswordShown(!confirmPasswordShown)

  const handleInputChange = e => {
    const { name, value } = e.target

    setRegisterForm({
      ...registerForm,
      [name]: value
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    authService
      .register(registerForm)
      .then(() => {
        navigate('/')
        setShowMessage(true)
        setMessageInfo({
          title: 'Confirm email',
          desc: 'Check your email and click the link to confirm your email.'
        })
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  const passwordMatch = e => {
    if (registerForm.password === '') {
      setDisabled(false)
    } else if (e.target.value !== registerForm.password) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <LoginFormComp onSubmit={handleSubmit}>
      <div className='form-floating mb-3'>
        <input
          type='email'
          className='form-control'
          id='registerEmailInput'
          placeholder='name@example.com'
          name='email'
          value={registerForm.email}
          onChange={handleInputChange}
        />
        <label htmlFor='registerEmailInput'>{t('register_form.email')}</label>
      </div>
      <div className='form-floating mb-3'>
        <input
          type='text'
          className='form-control'
          id='registerUsernameInput'
          placeholder='Username'
          name='username'
          value={registerForm.username}
          onChange={handleInputChange}
        />
        <label htmlFor='registerUsernameInput'>{t('register_form.username')}</label>
      </div>

      <LoginPasswordGroup className='mb-3'>
        <LoginDivPassword className='form-floating'>
          <LoginPasswordInput
            type={passwordShown ? 'text' : 'password'}
            className='form-control'
            id='registerPasswordInput'
            placeholder='Password'
            name='password'
            value={registerForm.password}
            onChange={handleInputChange}
            autoComplete='new-password'
          />
          <label htmlFor='registerPasswordInput'>{t('register_form.password')}</label>
        </LoginDivPassword>
        <ShowPasswordButton type='button' onClick={toggleShowPassword} tabIndex='-1'>
          {passwordShown ? <i className='fa-solid fa-eye-slash'></i> : <i className='fa-solid fa-eye'></i>}
        </ShowPasswordButton>
      </LoginPasswordGroup>

      <LoginPasswordGroup className='mb-3'>
        <LoginDivPassword className='form-floating'>
          <LoginPasswordInput
            type={confirmPasswordShown ? 'text' : 'password'}
            className='form-control'
            id='registerConfirmPasswordInput'
            placeholder='Confirm password'
            autoComplete='new-password'
            onChange={passwordMatch}
            required
          />
          <label htmlFor='registerConfirmPasswordInput'>{t('register_form.confirm_password')}</label>
          <NotMatchPass>{disabled ? 'Passwords does not match' : ''}</NotMatchPass>
        </LoginDivPassword>
        <ShowPasswordButton type='button' onClick={toggleShowConfirmPassword} tabIndex='-1'>
          {confirmPasswordShown ? <i className='fa-solid fa-eye-slash'></i> : <i className='fa-solid fa-eye'></i>}
        </ShowPasswordButton>
      </LoginPasswordGroup>

      <RegisterButton type='submit' disabled={disabled}>
        {t('register_form.submit')}
      </RegisterButton>
    </LoginFormComp>
  )
}

export default RegisterForm
