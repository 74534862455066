import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as S from './Expense.styled'

const Expense = ({ expense, month }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <S.ExpenseContainer
      onClick={() => {
        navigate(`/expenses/${expense._id}`)
      }}
    >
      <S.ExpenseLeft>
        <p>{expense.name.titleize()}</p>
        <p>
          {expense.date}
          {!month ? '' : `/${month}`}
        </p>
      </S.ExpenseLeft>
      <S.ExpenseRight>
        <p>
          {expense.amount} {t('currency')}
        </p>
        <p>{t(`categories.${expense.category}`).formatStr()}</p>
      </S.ExpenseRight>
    </S.ExpenseContainer>
  )
}

export default Expense
