import { useContext, useEffect, useState } from 'react'
import expensesService from '../../services/expenses.service'
import { MessageContext } from '../../context/userMessage.context'
import { areInSameWeek, getCurrentMonthNumber } from '../../utils/tools'
import Expense from '../Expense/Expense'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const NoExpenses = styled.p`
  text-align: center;
  margin-top: 1rem;
`

const ExpensesContainer = styled.div`
  @media screen and (min-width: 769px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6px;
  }
`

const WeekExpensesList = () => {
  const [expensesList, setExpensesList] = useState([])
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)
  const { t } = useTranslation()

  const fetchExpenses = () => {
    expensesService
      .getExpenses()
      .then(({ data }) => {
        const expensesList = data.expenses
        const sortedData = expensesList.sort((a, b) => a.date - b.date)
        setExpensesList(sortedData)
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      })
  }

  useEffect(() => {
    fetchExpenses() // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return expensesList.filter(exp => areInSameWeek(exp.date)).length !== 0 ? (
    <ExpensesContainer>
      {expensesList
        .filter(exp => areInSameWeek(exp.date))
        .map((expense, idx) => {
          return <Expense expense={expense} month={getCurrentMonthNumber()} key={idx} />
        })}
    </ExpensesContainer>
  ) : (
    <NoExpenses>{t('week_expenses')}</NoExpenses>
  )
}

export default WeekExpensesList
