import { useParams, useNavigate } from 'react-router-dom'
import CategoryList from '../../components/CategoryList/CategoryList'
import styled from 'styled-components'
import { HR, MobileContainer } from '../../utils/mixins'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  ${MobileContainer}
`
const HrBreak = styled.hr`
  ${HR}
`
const CategoryTitle = styled.div`
  text-align: center;
  margin: 1rem 0 1.8rem;

  & h1 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: -10px;
  }
`

const GoBackBtn = styled.button`
  background: none;
  border: 2px solid #86bbd8;
  border-radius: 5px;
  color: #86bbd8;
  font-size: 1.1rem;
  margin-top: 1rem;
  transition: 0.3s ease-in;

  &:hover {
    color: white;
    background-color: #86bbd8;
  }
`

const CategoryInfoPage = () => {
  const { t } = useTranslation()
  const { category_name } = useParams()
  const navigate = useNavigate()

  return (
    <Container>
      <GoBackBtn
        onClick={() => {
          navigate(-1)
        }}
      >
        {t('category_info_page.go_back')}
      </GoBackBtn>
      <CategoryTitle>
        <h1>{t(`categories.${category_name}`).formatStr()}</h1>
        <HrBreak />
      </CategoryTitle>
      <CategoryList category={category_name} />
    </Container>
  )
}

export default CategoryInfoPage
