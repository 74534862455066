/* eslint-disable no-extend-native */
import { isSameWeek } from 'date-fns'

const currentMonthNumber = new Date().getMonth() + 1
const currentMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(Date.now())
const currentDate = new Date()
const currentDay = currentDate.toString().split(' ')[2]

export function getCurrentMonthName() {
  return currentMonth
}

export function getCurrentMonthNumber() {
  return currentMonthNumber
}

export function parseToMonthName(monthDay) {
  const date = new Date()
  const newMonthDay = nextMonthIfPastDay(monthDay)
  date.setMonth(newMonthDay - 1)
  const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)

  return monthName
}

export function currentEvent(date) {
  const givenDay = date
  const datesDiff = givenDay - currentDay

  if (datesDiff >= 0) {
    return 'future'
  } else {
    return 'past'
  }
}

export function nextMonthIfPastDay(date) {
  const modifDate = new Date()
  const givenDay = date
  const datesDiff = givenDay - currentDay
  const nextMonth = new Date(modifDate.setMonth(modifDate.getMonth() + 1, 1))
  const nextMonthParsed = nextMonth.toLocaleDateString('en-US', { month: 'numeric' })

  if (datesDiff >= 0) {
    return currentMonthNumber
  } else {
    return nextMonthParsed
  }
}

export function areInSameWeek(day) {
  const modDate = new Date()
  modDate.setDate(day)

  return isSameWeek(modDate, currentDate, {
    weekStartsOn: 1
  })
}

// Prototypes

String.prototype.titleize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.formatStr = function () {
  return this.split('_').join(' ')
}
