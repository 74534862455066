import axios from 'axios'

class AuthService {
  constructor() {
    this.axios = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` })

    this.axios.interceptors.request.use(config => {
      const storedToken = localStorage.getItem('authToken') || sessionStorage.getItem('authToken')

      if (storedToken) {
        config.headers = { Authorization: `Bearer ${storedToken}` }
      }

      return config
    })
  }

  login(info) {
    return this.axios.post('/login', info)
  }

  register(info) {
    return this.axios.post('/register', info)
  }

  verify_email(id) {
    return this.axios.post(`/verify_email/${id}`)
  }

  requestChangePassword(email) {
    return this.axios.post(`/change_password/user/${email}`)
  }

  changePassword(id, new_password) {
    return this.axios.post(`/change_password/${id}`, new_password)
  }

  verify(token) {
    return this.axios.get('/verify', { headers: { Authorization: `Bearer ${token}` } })
  }
}

const authService = new AuthService()

export default authService
