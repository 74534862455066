import { useContext, useEffect, useState } from 'react'
import { MessageContext } from '../../context/userMessage.context'
import expensesService from '../../services/expenses.service'
import { currentEvent, getCurrentMonthNumber, nextMonthIfPastDay } from '../../utils/tools'
import Expense from '../Expense/Expense'
import styled from 'styled-components'
import { HR } from '../../utils/mixins'
import { t } from 'i18next'

const HrBreak = styled.hr`
  ${HR}
`
const IncomingExpenses = styled.div`
  text-align: center;
  margin: 1rem 0 1.8rem;

  & h4 {
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: -10px;
  }
`

const TotalExpenses = styled.p`
  font-weight: 600;
  text-align: center;

  & span {
    font-weight: 300;
  }
`

export const ExpensesContainer = styled.div`
  @media screen and (min-width: 769px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6px;
  }
`

const CategoryList = ({ category }) => {
  const [incomingExpensesList, setIncomingExpensesList] = useState([])
  const [pastExpensesList, setPastExpensesList] = useState([])
  const [isEmpty, setIsEmpty] = useState(false)
  const [expensesTotal, setExpensesTotal] = useState(0)
  const { setShowMessage, setMessageInfo } = useContext(MessageContext)

  const currentMonth = getCurrentMonthNumber()

  useEffect(() => {
    expensesService
      .getExpensesByCategory(category)
      .then(({ data }) => {
        const expensesList = data.expenses
        const expensesTotal = data.total

        setExpensesTotal(expensesTotal)
        expensesList.length === 0 ? setIsEmpty(true) : setIsEmpty(false)
        expensesList.forEach(expense => {
          if (currentEvent(expense.date) === 'future') {
            setIncomingExpensesList(currentExpenses => [...currentExpenses, expense])
          } else {
            setPastExpensesList(currentExpenses => [...currentExpenses, expense])
          }
        })
      })
      .catch(err => {
        setShowMessage(true)
        setMessageInfo({
          title: 'Error',
          desc: err.response.data.message
        })
      }) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isEmpty ? (
    <>
      <TotalExpenses>
        Total:{' '}
        <span>
          {expensesTotal}
          {t('currency')}
        </span>
      </TotalExpenses>
      <ExpensesContainer>
        {incomingExpensesList.map((expense, idx) => {
          return <Expense expense={expense} month={currentMonth} key={idx} />
        })}
      </ExpensesContainer>
      {pastExpensesList.length !== 0 && (
        <>
          <IncomingExpenses>
            <h4>{t('incoming_expense_list.incoming_expenses')}</h4>
            <HrBreak />
          </IncomingExpenses>
          <ExpensesContainer>
            {pastExpensesList.map((expense, idx) => {
              const nextMonth = nextMonthIfPastDay(expense.date)

              return <Expense expense={expense} month={nextMonth} key={idx} />
            })}
          </ExpensesContainer>
        </>
      )}
    </>
  ) : (
    <p>{t('category_list')}</p>
  )
}

export default CategoryList
