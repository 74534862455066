export const expenseCategories = [
  'services',
  'bank_services',
  'housing',
  'transportation',
  'health',
  'taxes',
  'food',
  'vehicle',
  'clothing',
  'personal_care',
  'leisure',
  'not_computable'
]
