import styled from 'styled-components'

export const NotRegistered = styled.div`
  margin-left: 10px;
  text-align: center;

  & a {
    color: #86bbd8;
  }
`

export const Container = styled.div`
  width: 95%;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media screen and (max-width: 769px) {
    flex-direction: column;
    height: 100%;
  }
`

export const LogoReminte = styled.img`
  display: block;
  margin: 2rem auto;
  width: 95%;
  max-width: 55rem;
`

export const DescriptionContainer = styled.div`
  width: 60%;
  padding: 0 2rem;

  @media screen and (max-width: 769px) {
    width: 100%;

    & p {
      display: none;
    }
  }
`

export const FormContainer = styled.div`
  width: 40%;

  @media screen and (max-width: 769px) {
    width: 100%;
  }
`

export const LogIn = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-left: 10px;
  color: #86bbd8;
  text-align: center;
`
